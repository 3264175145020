<template>
  <div class="row">
    <el-select
      v-model="region.province"
      class="select"
      :size="size"
      clearable
      :disabled="compile"
      value-key="id"
      placeholder="请选择省"
      @change="changeProvince"
    >
      <el-option
        v-for="item in options.province"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </el-select>
    <el-select
      v-model="region.city"
      class="select"
      :size="size"
      clearable
      value-key="id"
      :disabled="compile"
      placeholder="请选择市"
      @change="changeCity"
    >
      <el-option
        v-for="item in options.city"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </el-select>
    <el-select
      v-model="region.district"
      class="select"
      :size="size"
      clearable
      value-key="id"
      :disabled="compile"
      placeholder="请选择县/区"
      @change="changeRegion"
    >
      <el-option
        v-for="item in options.district"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
class Options {
  province = []; // 	省份选项
  city = []; // 	城市选项
  district = []; // 	县区选项
}
class Region {
  province = {
    id: '0',
    name: '0'
  };
  province_id = ''; // 	省份id
  province_name = ''; // 	省份name
  city = {
    id: '0',
    name: '0'
  };
  city_id = ''; // 	城市id
  city_name = ''; // 	城市name
  district = {
    id: '0',
    name: '0'
  };
  district_id = ''; // 	县区id
  district_name = ''; // 	县区name
}
import { regionList } from '@/api/user';
export default {
  props: {
    detail: {
      type: Object,
      default() {
        return {
          province_id: '',
          city_id: '',
          district_id: ''
        };
      }
    },
    size: {
      type: String,
      default: 'small'
    },
    compile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: new Options(), // 选项
      region: new Region()
    };
  },
  watch: {
    detail: {
      handler(newRegion) {
        const { province, city, district } = newRegion;
        this.region = { province, city, district };
        if (newRegion.province_id) this.getCity(1, 1);
        if (newRegion.city_id) this.getCity(2, newRegion.province_id);
        if (newRegion.district_id) this.getCity(3, newRegion.city_id);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getCity(1, 1);
    
  },
  methods: {
    // 获取城市
    async getCity(type, pid) {
      // console.log(type,pid)
      const data = await regionList({ type,pid });
      switch (type) {
        case 1:
          this.options.province = data.list;
          break;
        case 2:
          this.options.city = data.list;
          break;
        case 3:
          this.options.district = data.list;
          break;
      }
    },
    // 城市选择
    changeProvince(val) {
      // console.log(val);
      this.options.city = [];
      this.region.city_id = '';
      this.region.city_name = '';
      this.region.city = {};
      this.options.district = [];
      this.region.district_id = '';
      this.region.district_name = '';
      this.region.district = {};
      this.region.province_id = val.id;
      this.region.province_name = val.name;
      if (val) this.getCity(2, val.id);
      this.changeRegion();
    },
    changeCity(val) {
      // console.log(val);
      this.options.district = [];
      this.region.district_id = '';
      this.region.district_name = '';
      this.region.city_id = val.id;
      this.region.city_name = val.name;
      if (val) this.getCity(3, val.id);
      this.changeRegion();
    },
    changeRegion() {
      // console.log(this.region);
      this.region.district_id = this.region.district.id;
      this.region.district_name = this.region.district.name;
      this.$emit('getRegion', this.region);
    }
  }
};
</script>

<style scoped>
.select {
  flex: 1;
}
</style>
